import { createApp } from "vue";
import App from "./App.vue";
import IdleVue from "idle-vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import i18n from "@/core/plugins/i18n";

import dayjs from "dayjs"; // if you installed element plus, you will have dayjs as a dependency by default
import "dayjs/locale/es"; // import the locale you want

dayjs.locale("es");

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
import "bootstrap";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import Maska from "maska";

import ElementPlus from "element-plus";
import es from "element-plus/es/locale/lang/es";

const app = createApp(App);

app.use(ElementPlus, {
  locale: es,
});

app.use(store);
app.use(router);
app.use(i18n);
app.use(ElementPlus);

const mapsApiKey = process.env.VUE_APP_MAPS_API_KEY;

app.use(VueGoogleMaps, {
  load: {
    key: mapsApiKey,
    libraries: "places",
  },
});
app.use(IdleVue, {
  store,
  idleTime: 60000,
  startAtIdle: true,
});

app.use(Maska);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.mount("#app");
