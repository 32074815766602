
import { defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import axios, { AxiosError } from "axios";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import JwtService from "@/core/services/JwtService";
import store from "@/store";

export default defineComponent({
  name: "app",

  setup() {
    const router = useRouter();
    const isIdle = ref(false);

    store.subscribe((mutation) => {
      if (
        mutation.type == "idleVue/IDLE_CHANGED" &&
        store.getters.isUserAuthenticated
      ) {
        isIdle.value = mutation.payload;
        if (mutation.payload == false) {
          store.dispatch(Actions.VERIFY_AUTH).catch(() => {
            router.push("/login");
          });
        }
      }
    });

    const token = JwtService.getToken();

    if (!token) {
      router.push("/login");
    }

    axios.interceptors.request.use((req) => {
      
      if (req.url != "auth" && !store.getters.isUserAuthenticated) {
        return Promise.reject();
      } else {
        return req;
      }
    });

    axios.defaults.timeout = process.env.VUE_APP_AXIOS_TIMEOUT;

    axios.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        if (error.code === "ECONNABORTED" || error.message.includes("timeout")) {
          Swal.fire({
            title: "Timeout",
            text: "El servidor está tardando en responder.",
            icon: "error",
            confirmButtonText: "Aceptar",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return { store, isIdle };
        }

        if (error.response?.status === 401) {
        store.dispatch(Actions.LOGOUT);
        router.push("/login");
        Swal.fire({
          title: "Sesion expirada",
          text: "Su sesión ha expirado o no tiene una sesión válida con el servidor y fue redireccionado a la página de ingreso.",
          icon: "error",
          confirmButtonText: "Aceptar",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return { store, isIdle };
      }

        if (!error.response) {
          Swal.fire({
            title: "Server Error",
            text: "No hay respuesta del servidor",
            icon: "error",
            confirmButtonText: "Aceptar",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return { store, isIdle };
        }

        // For other types of errors
        return Promise.reject(error);
      }
    );

    return { store, isIdle };
  },
});
